import React, { useEffect } from 'react';
import Helmet               from 'react-helmet';
import useScript            from 'react-script-hook';

import './style.css';

const SmartMeeting = () => {

  const apiKey = '63dd364533820401b655a0f09a9805ca0bfc6a24ad307a63a9f987053dcadc5d';
  const settings = {
    debug: true,
    api: {
      key: apiKey
    },
    'credentials': {
      'form': {
        'fields': {
          'phone': {
            'required': true
          },
          'message': {
            'required': true
          }
        }
      }
    },
    'labeling': {
      'credentials': {
        'body': 'Bitte nennen Sie uns Ihre Telefonnummer, Ihre E-Mail-Adresse und Ihr Anliegen (ein Stichwort z.B. Beratung Eheringe genügt) für ein detailliertes Beratungsgespräch.'
      }
    }
  };

  const [loading] = useScript({
    src: 'https://service.smartmeeting.online/plugin/dist/1.2.2/smartmeeting-website-plugin.js',
    checkForExisting: true
  })

  useEffect(() => {
    if (settings && !loading) {
      new window.SmartMeetingWebsitePlugin('smartmeeting', settings);
    }
  }, [settings, loading]);

  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='//service.smartmeeting.online/plugin/dist/1.2.2/smartmeeting-website-plugin.css' />
        <link rel='stylesheet' href='//service.smartmeeting.online/plugin/dist/1.2.2/smartmeeting-theme.css' />
      </Helmet>
      <div style={{ minHeight: '300px' }} id="smartmeeting"/>
    </>
)
};

export default SmartMeeting;
